var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","xs":"12","md":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"overflow-y-auto elevation-10",attrs:{"outlined":""}},[_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","height":("" + ((_vm.OPHeight - 200) / 2)),"fixed-header":true,"headers":_vm.operationalHeader,"items-per-page":_vm.GetAllDashboardParameterList.filter(
                        function (item) { return item.tag_type == 'AI' &&
                          (item.show_on_dashboard == undefined ||
                            item.show_on_dashboard == true); }
                      ).length,"items":_vm.sortMethod(
                        _vm.GetAllDashboardParameterList.filter(
                          function (item) { return item.tag_type == 'AI' &&
                            (item.show_on_dashboard == undefined ||
                              item.show_on_dashboard == true); }
                        )
                      ),"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primaryColor"},[_vm._v(" Operational Parameters ")])])],1)]},proxy:true},{key:"item.tag_description",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.tag_description))])]}},{key:"item.actual_value",fn:function(ref){
                      var item = ref.item;
return [_c('v-card',{staticClass:"borderRadius elevation-0 px-1",attrs:{"align":"center","color":item.color == undefined
                            ? 'grey lighten-3'
                            : item.color}},[_c('div',{staticClass:"fontSize",class:item.color == 'green' ? 'white--text' : ''},[_vm._v(" "+_vm._s(item.actual_value != undefined ? item.actual_value.toString() : "-")+" ")])])]}},{key:"item.commissioning_value",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.commissioning_value)+" ")])]}}],null,true)}),(_vm.renderData)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"height":("" + ((_vm.OPHeight - 230) / 2)),"fixed-header":true,"dense":"","headers":_vm.alarmHeader,"items-per-page":10,"items":_vm.GetAllInitialAlarmsList,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primaryColor"},[_vm._v(" Alarms ")])])],1)]},proxy:true},{key:"item.tag_description",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.tag_description))]),_c('div',{staticClass:"fontSizeSmall"},[_vm._v(" "+_vm._s(item.alarm_time ? new Date(item.alarm_time * 1000).toLocaleString( "en-In" ) : "-")+" ")])]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","xs":"12","md":"6"}},[(_vm.renderData)?_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-actions',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-bold primaryColor"},[_vm._v("Summary")]),_c('v-spacer'),_c('div',[_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Asset Name:")]),_c('span',{staticClass:"green-text"},[_vm._v(" "+_vm._s(_vm.$store.getters.get_asset_object.asset_name))])]),_c('br'),_c('span',{staticClass:"pt-n4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Asset ID:")]),_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.get_asset_object.asset_type))])])])],1)],1):_vm._e(),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-divider'),_c('v-card-text',[_c('v-row',_vm._l((_vm.plant_types),function(plant){return _c('v-col',{key:plant,attrs:{"cols":"12","sm":"12","xs":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold text--primary"},[_c('u',[_vm._v(_vm._s(plant)+" ( "+_vm._s(plant == "UF" ? "40M³/Hr" : plant == "RO1" ? "28M³/Hr" : "6.5 M³/Hr")+" )")])]),_c('span',{staticClass:"font-weight-bold text--primary"},[_vm._v("Step :")]),_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":_vm.stepObject[plant] == undefined
                          ? 'red'
                          : _vm.stepObject[plant]['step_name']
                              .toLowerCase()
                              .includes('idle')
                          ? 'red'
                          : _vm.stepObject[plant].color.toLowerCase()}},[_vm._v("mdi-checkbox-blank-circle")]),_c('span',{staticClass:"fontSize font-weight-bold",class:_vm.stepObject[plant] == undefined
                          ? 'red--text'
                          : _vm.stepObject[plant]['step_name']
                              .toLowerCase()
                              .includes('idle')
                          ? 'red--text'
                          : ((_vm.stepObject[plant].color.toLowerCase()) + "--text")},[_vm._v(_vm._s(_vm.stepObject[plant] == undefined ? "IDLE STEP" : _vm.stepObject[plant]["step_name"].toUpperCase())+" ")]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Recovery (%) :")]),(
                        _vm.stepObject[plant] != undefined &&
                        _vm.stepObject[plant]['step_name']
                          .toLowerCase()
                          .includes('idle') == false
                      )?_c('span',{class:parseInt(_vm.recovery_percentage[plant]) > 100
                          ? 'red--text'
                          : ''},[_vm._v(" "+_vm._s(_vm.recovery_percentage[plant] && _vm.recovery_percentage[plant] != "" ? parseInt(_vm.recovery_percentage[plant]) > 100 ? "ERROR" : _vm.recovery_percentage[plant] : "")+" ")]):_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v("IDLE")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v("Status")]),(_vm.renderData)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.sortMethod(
                              _vm.GetAllDashboardParameterList.filter(
                                function (item) { return item.tag_type == 'DI' &&
                                  item.status_or_feedback == 'STATUS' &&
                                  item.plant_name == plant &&
                                  (item.show_on_dashboard == undefined ||
                                    item.show_on_dashboard == true); }
                              )
                            )),function(item){return _c('tr',{key:item.name,style:(("background-color:" + (!item.bgcolor ? '#F5F5F5' : item.bgcolor)))},[_c('td',{staticClass:"fontSize",staticStyle:{"width":"220px"}},[_vm._v(" "+_vm._s(item.tag_description)+" ")]),_c('td',[_c('v-icon',{attrs:{"x-small":"","color":!item.color || item.color == 'RED'
                                    ? 'red'
                                    : item.color == 'GREEN'
                                    ? 'green'
                                    : 'yellow'}},[_vm._v("mdi-checkbox-blank-circle")])],1)])}),0)]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticClass:"font-weight-bold"},[_vm._v("Feedback")]),(_vm.renderData)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.sortMethod(
                              _vm.GetAllDashboardParameterList.filter(
                                function (item) { return item.tag_type == 'DI' &&
                                  item.status_or_feedback == 'FEEDBACK' &&
                                  item.plant_name == plant &&
                                  (item.show_on_dashboard == undefined ||
                                    item.show_on_dashboard == true); }
                              )
                            )),function(item){return _c('tr',{key:item.name,style:(("background-color:" + (!item.bgcolor ? '#F5F5F5' : item.bgcolor)))},[_c('td',{staticClass:"fontSize",staticStyle:{"width":"220px"}},[_vm._v(" "+_vm._s(item.tag_description)+" ")]),_c('td',[_c('v-icon',{attrs:{"x-small":"","color":!item.color || item.color == 'RED'
                                    ? 'red'
                                    : item.color == 'GREEN'
                                    ? 'green'
                                    : 'yellow'}},[_vm._v("mdi-checkbox-blank-circle")])],1)])}),0)]},proxy:true}],null,true)}):_vm._e()],1)],1)}),1)],1)],1)],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","xs":"12","md":"3"}},[_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primaryColor"},[_vm._v("Daily Logs")])])],1),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-divider'),_c('v-card-text',[_c('v-divider'),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.plant_types),function(plant){return _c('v-col',{key:plant,attrs:{"cols":"12","sm":"12","xs":"12","md":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"fontSize",attrs:{"width":"120px"}},[_vm._v(" "+_vm._s(plant)+" Start / Stop ")]),(_vm.dailyLogs[plant] != undefined)?_c('td',{staticClass:"fontSize"},[_c('v-row',{staticClass:"borderLeft text-right",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_vm._v("Start")]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_vm._v("Stop")])],1),_vm._l((_vm.dailyLogs[plant]
                                .slice()
                                .reverse()),function(time,idx){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(idx < 4),expression:"idx < 4"}],key:idx,staticClass:"borderLeft text-right fontSize",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_vm._v(_vm._s(time.start != undefined ? new Date( time.start * 1000 ).toLocaleTimeString() : "-"))]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_vm._v(_vm._s(time.stop != undefined ? new Date( time.stop * 1000 ).toLocaleTimeString() : "-"))])],1)})],2):_c('td',{staticClass:"fontSize text-right"},[_vm._v("-")])]),_c('tr',[_c('td',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(plant)+" total hours of Operation : ")]),(
                              _vm.dailyLogs[
                                ((plant.toLowerCase()) + "_total_hours_of_operation")
                              ] != undefined
                            )?_c('td',{staticClass:"fontSize text-right"},[_vm._v(" "+_vm._s(_vm.convertTimeToHRMinSec( _vm.dailyLogs[ ((plant.toLowerCase()) + "_total_hours_of_operation") ], _vm.dailyLogs[plant], plant ))+" ")]):_c('td',{staticClass:"fontSize text-right"},[_vm._v("-")])]),_c('tr',[_c('td',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(plant)+" Water Produced (M³): ")]),(
                              _vm.dailyLogs[
                                ((plant.toLowerCase()) + "_water_produced")
                              ] != undefined
                            )?_c('td',{staticClass:"fontSize text-right"},[_vm._v(" "+_vm._s(parseFloat( _vm.dailyLogs[ ((plant.toLowerCase()) + "_water_produced") ] ) ? _vm.dailyLogs[ ((plant.toLowerCase()) + "_water_produced") ] : "-")+" ")]):_c('td',{staticClass:"fontSize text-right"},[_vm._v("-")])])])]},proxy:true}],null,true)}),_c('v-divider')],1)}),1),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"fontSize"},[_vm._v("DMF Manual Backwash (M³/Day):")]),(
                          _vm.dailyLogs["dmf_manual_backwash_value"] != undefined
                        )?_c('td',{staticClass:"fontSize text-right"},[_vm._v(" "+_vm._s(_vm.dailyLogs["dmf_manual_backwash_value"])+" ")]):_c('td',{staticClass:"fontSize text-right"},[_vm._v("-")])]),_c('tr',[_c('td',{staticClass:"fontSize"},[_vm._v("UF Plant Inlet (M³/Day):")]),(_vm.dailyLogs["uf_plant_inlet"] != undefined)?_c('td',{staticClass:"fontSize text-right"},[_vm._v(" "+_vm._s(_vm.dailyLogs["uf_plant_inlet"])+" ")]):_c('td',{staticClass:"fontSize text-right"},[_vm._v("-")])])])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }